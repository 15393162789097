import { css } from '@emotion/core';
import React, { useState, useMemo, useEffect } from 'react';
import ReactWordcloud from 'react-wordcloud';
import Articles from '../MarketPage/Articles';
import * as d3 from 'd3';
import axios from 'axios';
import { Loading } from 'carbon-components-react';

const TopArticles = () => {
  const api_prefix =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000/api/v5/wordcloud/postcovid'
      : '/api/wordcloud/postcovid';

  const [words, setWords] = useState(null);
  const [word, setWord] = useState(null);
  const [corpus, setCorpus] = useState(null);
  useEffect(() => {
    // console.log(`${api_prefix}/words?market=all`)
    axios
      .get(`${api_prefix}/words?market=all`)
      .then((res) => res.data)
      .then((data) => {
        // console.log(data)
        // console.log(`${api_prefix}/corpus?market=all&word=${data[0].text}`)
        // console.log(data[0].text)
        axios
          .get(`${api_prefix}/corpus?market=all&word=${data[0].text}`)
          .then((res2) => {
            // console.log(res2.data)
            setCorpus(res2.data);
            setWords(data);
            setWord(data[0].text);
          })
          .catch(function (error1) {
            console.log(error1);
          });
      })
      .catch(function (error2) {
        console.log(error2);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    // colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
    enableTooltip: false,
    deterministic: true,
    fontSizes: [15, 70],
    fontStyle: 'normal',
    fontFamily: 'IBM Plex Sans',
    fontWeight: 'normal',
    padding: 3,
    rotations: 3,
    rotationAngles: [0, 0],
    // scale: 'sqrt',
    // spiral: 'archimedean',
    transitionDuration: 1000,
  };
  const getCallback = (callback) => {
    return function (word, event) {
      const isActive = callback !== 'onWordMouseOut';
      const text = d3.select(event.target);
      text
        .on('click', () => {
          if (isActive) {
            axios
              .get(`${api_prefix}/corpus?market=all&word=${word.text}`)
              .then(function (response) {
                setCorpus(response.data);
                setWord(word.text);
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        })
        .attr('background', 'white')
        .attr('text-decoration', isActive ? 'underline' : 'none');
    };
  };

  const callbacks = {
    getWordTooltip: (word) =>
      `The word "${word.text}" appears ${word.value} times.`,
    onWordClick: getCallback('onWordClick'),
    onWordMouseOut: getCallback('onWordMouseOut'),
    onWordMouseOver: getCallback('onWordMouseOver'),
  };
  return useMemo(() => {
    return words === null ? (
      <Loading active />
    ) : (
      <div
        css={css`
          margin: 0 auto;
          max-width: 100%;
        `}
      >
        <h2
          style={{
            paddingTop: '40px',
            paddingBottom: '0px',
          }}
        >
          Top Articles Related To Post-Pandemic Recovery
        </h2>

        <div
          className="bx--row"
          style={{ paddingTop: '30px', paddingLeft: '15px' }}
        >
          <div
            style={{
              height: 500,
              width: '100%',
              backgroundColor: 'white',
            }}
            className="bx--col-md-10 bx--col-lg-10"
          >
            <ReactWordcloud
              options={options}
              words={words}
              callbacks={callbacks}
            />
          </div>
          <div className="bx--col-md-6 bx--col-lg-6">
            {/* <p>{word}</p> */}
            {/* <p>{corpus['0']['body']}</p> */}
            <Articles key={`postcovid_${word}`} word={word} corpus={corpus} />
          </div>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [word]);
};
export default TopArticles;

import React, { useEffect, useContext } from 'react';
import * as d3 from 'd3';
import DashboardContext from '../../context/dashboard/dashboardContext';
import MarketpageContext from '../../context//marketpage/marketpageContext';
import { Library } from '@observablehq/stdlib';
const TreeMap = ({ market }) => {
  const dashboardContext = useContext(DashboardContext);
  const marketpageContext = useContext(MarketpageContext);
  const { tableData } = dashboardContext;
  const { advIndData } = marketpageContext;
  const selectedMarketName = tableData.find((m) => m.id === market).name;

  const data = advIndData[market];
  // console.log(advIndData);
  const { DOM } = new Library();

  var margin = { top: 20, right: 20, bottom: 20, left: 20 },
    width = document.body.clientWidth - 200 - margin.left - margin.right,
    height = 500;
  var color = d3.scaleOrdinal(d3.schemeCategory10);
  var format = d3.format(',d');
  useEffect(() => {
    // console.log(market);
    var treemap = (data) =>
      d3
        .treemap()
        .tile(d3['treemapBinary'])
        .size([width, height])
        .padding(3)
        .round(true)(
        d3
          .hierarchy(data)
          .sum((d) => d.value)
          .sort((a, b) => b.value - a.value)
      );
    var root = treemap(data);

    var svg = d3
      .select('#adv-ind-treemap')
      .append('svg')
      .attr('viewBox', [0, 0, width, height])
      .style('fill', 'white')
      .style('font', '16px')
      .style('line-height', 3);

    var leaf = svg
      .selectAll('g')
      .data(root.leaves())
      .join('g')
      .attr('transform', (d) => `translate(${d.x0},${d.y0})`);

    // leaf.append('title').text(
    //   d =>
    //     `${d
    //       .ancestors()
    //       .reverse()
    //       .map(d => d.data.name)
    //       .join('/')}\n${format(d.value)}`
    // );

    leaf.append('title').text(
      (d) =>
        `${d
          .ancestors()
          .reverse()
          .map((d) => d.data.name)
          .join('/')}`
    );

    leaf
      .append('rect')
      .attr('id', (d) => (d.leafUid = DOM.uid('leaf')).id)
      .attr('fill', (d) => {
        while (d.depth > 1) d = d.parent;
        // return '#0f62fe';
        return color(d.data.name);
      })
      .attr('fill-opacity', 1)
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0);

    leaf
      .append('clipPath')
      .attr('id', (d) => (d.clipUid = DOM.uid('clip')).id)
      .append('use')
      .attr('xlink:href', (d) => d.leafUid.href);

    leaf
      .append('text')
      .attr('clip-path', (d) => d.clipUid.href)
      .selectAll('tspan')
      .data(function (d) {
        return d.data.name.split(' ').concat(format(d.value));
      })
      .join('tspan')
      .attr('x', 3)
      .attr(
        'y',
        (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 1.1}em`
      )
      .attr('fill-opacity', (d, i, nodes) =>
        i === nodes.length - 1 ? 0.8 : null
      )
      .text(function (d, i, nodes) {
        if (
          parseInt(JSON.stringify(nodes[nodes.length - 1]).split('"')[3]) > 1 &&
          isNaN(d) // not show the final number
        ) {
          return d;
        } else {
          return '';
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
      <h3 style={{ paddingBottom: '10px' }}>
        Top Sectors of Total Advanced Industry Employment in{' '}
        {selectedMarketName} - 2021
      </h3>
      <div id="adv-ind-treemap"></div>
    </div>
  );
};

export default TreeMap;

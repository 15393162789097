import React, { useState } from 'react';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import './_pagination.scss';
import { Loading } from 'carbon-components-react';

const Article = ({ word, article }) => {
  return (
    <div style={{ overflowY: 'auto', flex: 1, height: 350 }}>
      <h3>{article['title']}</h3>
      <p style={{ fontStyle: 'italic', paddingTop: '10px' }}>
        {article['source_name']}
      </p>
      <p className="publication-date">{article['publication_date']}</p>
      <p
        style={{
          fontStyle: 'italic',
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
      >
        Keyword: <strong>{word}</strong>
      </p>

      {article['url'] != null && (
        <a
          el="noopener noreferrer"
          style={{
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
          href={article['url']}
          target="_blank"
        >
          Link
        </a>
      )}

      <p style={{ paddingTop: '10px' }}>{article['snippet']}</p>
      <p style={{ paddingTop: '10px' }}>{article['body']}</p>
    </div>
  );
};

const Articles = ({ word, corpus }) => {
  const [pageNumber, setPageNumber] = useState(1);

  return corpus !== null ? (
    <div
      style={{
        height: 500,
        position: 'relative',
        padding: '20px',
        backgroundColor: 'white',
      }}
    >
      {/* <h3>{Object.keys(corpus).length}</h3> */}
      <Article word={word} article={corpus['' + pageNumber - 1]} />

      <Pagination
        style={{
          position: 'absolute',
          bottom: 50,
        }}
        onChange={setPageNumber}
        current={pageNumber}
        defaultPageSize={1}
        total={Object.keys(corpus).length}
      />
    </div>
  ) : (
    <Loading active />
  );
};

export default Articles;

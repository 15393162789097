import React, { useContext, useEffect } from 'react';
import { Content } from 'carbon-components-react/lib/components/UIShell';
import Footer from '../../components/Footer/Footer';
import { Loading } from 'carbon-components-react';
import AuthContext from '../../context/auth/authContext';
import DashboardContext from '../../context/dashboard/dashboardContext';
import EditableTable from './EditableTable';

const AdminPage = () => {
  const authContext = useContext(AuthContext);
  const dashboardContext = useContext(DashboardContext);
  const { tableData, getFullDatatable, getOriginalDatatable, originalTableData } =
    dashboardContext;
  const { loading, user } = authContext;

  useEffect(() => {
    getFullDatatable();
    getOriginalDatatable();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <div className="bx--grid bx--grid--full-width landing-page">
      <div className="bx--row landing-page__banner landing-page__r1">
        <div className="bx--col-lg-16">
          {user !== null && user['admin'] ? (
            <EditableTable />
          ) : (
            <h1>Admin Page - Access Restricted</h1>
          )}
        </div>
      </div>
    </div>
  );
  return (
    <Content id="main-content">
      {!loading && tableData !== null && originalTableData !== null ? (
        <>{content}</>
      ) : (
        <Loading active />
      )}

      <Footer page="variable" />
    </Content>
  );
};

export default AdminPage;

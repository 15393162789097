import React, { useContext, useEffect } from 'react';

import MarketDetails from './MarketDetails';
import { Content } from 'carbon-components-react/lib/components/UIShell';
import DropdownTitle from './DropdownTitle';
import RevparChart from './RevparChart';
import AccordionChart from './AccordionChart';
import TopArticles from './TopArticles';
import TopArticles2 from './TopArticles2';
import SupplyPipeline from './SupplyPipeline';
import Footer from '../../components/Footer/Footer';
import AuthContext from '../../context/auth/authContext';
import MarketpageContext from '../../context/marketpage/marketpageContext';
import DashboardContext from '../../context/dashboard/dashboardContext';
import { v4 as uuidv4 } from 'uuid';
import ReactLoading from 'react-loading';
const MarketPage = ({ match, history }) => {
  const marketId = match.params.id;
  const authContext = useContext(AuthContext);
  const { loading } = authContext;
  const marketpageContext = useContext(MarketpageContext);
  const dashboardContext = useContext(DashboardContext);
  const {
    tableData,
    segmentationData,
    variableData,
    usRevparData,
    marketData,
    marketCategory,
    getUsRevparData,
    getMarketData,
    getSegmentation,
    getVariableData,
    getFullDatatable,
    getMarketCategory
  } = dashboardContext;

  const {
    variableAvg,
    topVariableData,
    topVariableList,
    variableVis1,
    variableVis2,
    advIndData,
    hotelPipelineData,
    getAdvIndData,
    getHotelPipelineData,
    getVariableAvg,
    getTopVariableData,
    getTopVariableList,
    getVariableVis1,
    getVariableVis2,
  } = marketpageContext;

  useEffect(() => {
    window.scrollTo(0, 0);
    getAdvIndData();
    getHotelPipelineData();
    getVariableAvg();
    getTopVariableData();
    getTopVariableList();
    getVariableVis1();
    getVariableVis2();
    getUsRevparData();
    getMarketData();
    getSegmentation();
    getVariableData();
    getFullDatatable();
    getMarketCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <div className="bx--grid bx--grid--full-width landing-page">
      <div className="bx--row landing-page__banner landing-page__r1">
        <div className="bx--col-lg-16">
          <DropdownTitle marketId={marketId} history={history} />
          <MarketDetails marketId={marketId} />
          <RevparChart marketId={marketId} />
          <AccordionChart marketId={marketId} />
          <SupplyPipeline marketId={marketId} />
          {[
            'pittsburgh',
            'maui',
            'tucson',
            'richmond',
            'cleveland',
            'sacramento',
            'oahu',
            'st-louis',
            'kansas-city',
            'milwaukee',
            'memphis',
            'oklahoma-city',
            'detroit',
            'jacksonville',
            'columbus',
            'buffalo',
            'cincinnati',
          ].indexOf(marketId) < 0 ? (
            <TopArticles key={marketId + uuidv4()} marketId={marketId} />
          ) : (
            <div></div>
          )}
          {[
            'pittsburgh',
            'maui',
            'tucson',
            'richmond',
            'cleveland',
            'sacramento',
            'oahu',
            'st-louis',
            'kansas-city',
            'memphis',
            'milwaukee',
            'oklahoma-city',
            'detroit',
            'jacksonville',
            'columbus',
            'louisville',
            'buffalo',
            'cincinnati',
            'raleigh'
          ].indexOf(marketId) < 0 ? (
            <TopArticles2 key={marketId + uuidv4()} marketId={marketId} />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
  return (
    <Content id="main-content">
      {!loading &&
      tableData !== null &&
      marketData !== null &&
      usRevparData !== null &&
      marketCategory != null &&
      segmentationData !== null &&
      hotelPipelineData !== null &&
      variableAvg !== null &&
      topVariableData !== null &&
      topVariableList !== null &&
      variableVis1 !== null &&
      variableVis2 !== null &&
      advIndData !== null &&
      variableData !== null ? (
        <>{content}</>
      ) : (
        <ReactLoading
          className="preloader"
          type={'spin'}
          color={'#0062ff'}
          height={60}
          width={60}
        />
      )}

      <Footer page="market" />
    </Content>
  );
};

export default MarketPage;

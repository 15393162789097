export const SET_SEGMENT = 'SET_SEGMENT';
export const SET_VARIABLE = 'SET_VARIABLE';
export const SET_YEAR = 'SET_YEAR';
export const SET_VISUALIZATION = 'SET_VISUALIZATION';
export const GET_MARKETS = 'GET_MARKETS';
export const GET_DATATABLE = 'GET_DATATABLE';
export const GET_SEG_DATATABLE = 'GET_SEG_DATATABLE';
export const FLIP_MENU_STATUS = 'FLIP_MENU_STATUS';
export const SET_MARKET = 'SET_MARKET';
export const GET_ORIGINAL_DATATABLE = 'GET_ORIGINAL_DATATABLE';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_ADV_IND_DATA = 'GET_ADV_IND_DATA';
export const GET_HOTEL_PIPELINE_DATA = 'GET_HOTEL_PIPELINE_DATA';
export const GET_VARIABLE_AVG = 'GET_VARIABLE_AVG';
export const GET_TOP_VARIABLE_DATA = 'GET_TOP_VARIABLE_DATA';
export const GET_TOP_VARIABLE_LIST = 'GET_TOP_VARIABLE_LIST';
export const GET_VARIABLE_VIS_1 = 'GET_VARIABLE_VIS_1';
export const GET_VARIABLE_VIS_2 = 'GET_VARIABLE_VIS_2';

export const GET_MARKET_DATA = 'GET_MARKET_DATA';
export const GET_SEGMENTATION = 'GET_SEGMENTATION';
export const GET_US_REVPAR_GROWTH = 'GET_US_REVPAR_GROWTH';
export const GET_VARIABLES = 'GET_VARIABLES';
export const GET_FULL_DATATABLE = 'GET_FULL_DATATABLE';
export const GET_VARIABLE_CSV = 'GET_VARIABLE_CSV';
export const GET_TOOLTIP = 'GET_TOOLTIP';

export const GET_MARKET_CATEGORY = 'GET_MARKET_CATEGORY';
export const GET_SENTIMENT_INDEX = 'GET_SENTIMENT_INDEX';
import React, { Fragment, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import Search20 from '@carbon/icons-react/lib/search/20';
// import Notification20 from '@carbon/icons-react/lib/notification/20';
// import AppSwitcher20 from '@carbon/icons-react/lib/app-switcher/20';
import Home20 from '@carbon/icons-react/lib/home/20';
import Location20 from '@carbon/icons-react/lib/location/20';
// import ChartLine20 from '@carbon/icons-react/lib/chart--line/20';
// import Bookmark20 from '@carbon/icons-react/lib/bookmark/20';
import UserSettings20 from '@carbon/icons-react/lib/user--admin/20';
// import Blog20 from '@carbon/icons-react/lib/blog/20';
import Logout20 from '@carbon/icons-react/lib/logout/20';
import Coronavirus20 from '@carbon/icons-react/lib/coronavirus/20';
import HeaderContainer from 'carbon-components-react/lib/components/UIShell/HeaderContainer';

import {
  Header,
  HeaderName,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SideNav,
  SideNavItems,
  SideNavLink,
} from 'carbon-components-react/lib/components/UIShell';
import AuthContext from '../../context/auth/authContext';

const HorizonHeader = () => {
  const authContext = useContext(AuthContext);

  const { isAuthenticated, logout, loadUser, user } = authContext;
  useEffect(() => {
    loadUser();
    // console.log(isAuthenticated)
    // console.log(user);
    // eslint-disable-next-line
  }, []);
  const LoginHeader = (
    <Header aria-label="IBM Platform Name">
      <HeaderName href="http://www.research.ibm.com/">Research</HeaderName>
    </Header>
  );

  const DashboardHeader = (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <>
          <Header aria-label="IBM Platform Name">
            <HeaderName href="http://www.research.ibm.com/">
              Research
            </HeaderName>

            <HeaderGlobalBar>
              <HeaderGlobalAction
                aria-label="Notifications"
                onClick={() => {
                  logout();
                }}
              >
                <Logout20 />
              </HeaderGlobalAction>
            </HeaderGlobalBar>
            <SideNav
              isFixedNav
              expanded={true}
              isChildOfHeader={true}
              aria-label="Side navigation"
            >
              <SideNavItems>
                <SideNavLink element={Link} to="/">
                  <Home20 />
                </SideNavLink>
                <SideNavLink element={Link} to="/market/atlanta">
                  <Location20 />
                </SideNavLink>
                <SideNavLink element={Link} to="/post-covid">
                  <Coronavirus20 />
                </SideNavLink>

                <SideNavLink element={Link} to="/admin">
                  <UserSettings20 />
                </SideNavLink>
              </SideNavItems>
            </SideNav>
          </Header>
        </>
      )}
    />
  );

  return (
    <Fragment>{!isAuthenticated ? LoginHeader : DashboardHeader}</Fragment>
  );
};

export default HorizonHeader;

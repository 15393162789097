import React, { useContext } from 'react';
import GenericUSRevparGrowthChart from './GenericUSRevparGrowthChart';
import DashboardContext from '../../context/dashboard/dashboardContext';

const USRevparGrowthChart = () => {
  const dashboardContext = useContext(DashboardContext);
  const { usRevparData } = dashboardContext;
  const lastYearForecast =
    Math.round(
      usRevparData[usRevparData.length - 1]['Forecasted RevPAR Growth'] * 1e3
    ) / 1e1;

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div id="two-columns">
        <div
          className="bx--col-md-4 bx--col-lg-4"
          style={{ paddingTop: '10px', paddingBottom: '40px' }}
        >
          <p>
            US RevPAR forecast 2019-2026: <strong>1.13%</strong>{' '}
          </p>

          <p>
            US RevPAR forecast 2021-2026: <strong>{lastYearForecast}%</strong>
          </p>
          <p style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            Variables used for macroeconomic analysis:
          </p>
          <p> - Term spread = 10 year yield – 3 month yield</p>
          <p> - US GDP</p>
          <p> - US housing price growth</p>
          <p> - US RevPAR growth</p>
          <p style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            Assumes US RevPAR returns to 2019 levels in 2023/2024
          </p>
        </div>
        <div className="bx--col-md-12 bx--col-lg-12">
          <h3 style={{ paddingBottom: '20px' }}>
            5-year US RevPAR Growth CAGR: Actual vs. Forecasted
          </h3>
          <GenericUSRevparGrowthChart />
        </div>
      </div>
    </div>
  );
};
export default USRevparGrowthChart;

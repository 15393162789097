import {
  SET_VARIABLE,
  SET_YEAR,
  SET_VISUALIZATION,
  GET_DATATABLE,
  FLIP_MENU_STATUS,
  GET_US_REVPAR_GROWTH,
  GET_MARKET_DATA,
  GET_SEGMENTATION,
  GET_VARIABLES,
  GET_FULL_DATATABLE,
  GET_VARIABLE_CSV,
  GET_ORIGINAL_DATATABLE,
  GET_TOOLTIP,
  GET_MARKET_CATEGORY
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_MARKET_CATEGORY:
      return {
        ...state,
        marketCategory: action.payload
      }
    case GET_TOOLTIP:
      return {
        ...state,
        tooltip: action.payload
      }
    case GET_VARIABLE_CSV:
      return {
        ...state,
        variableCsv: action.payload
      };
    case GET_VARIABLES:
      return {
        ...state,
        variableData: action.payload
      };
    case GET_US_REVPAR_GROWTH:
      return {
        ...state,
        usRevparData: action.payload
      };
    case GET_MARKET_DATA:
      return {
        ...state,
        marketData: action.payload
      };
    case GET_SEGMENTATION:
      return {
        ...state,
        segmentationData: action.payload
      };
    case FLIP_MENU_STATUS:
      return {
        ...state,
        isMenuOpened: !state.isMenuOpened
      };
    case GET_ORIGINAL_DATATABLE:
      return {
        ...state,
        originalTableData: action.payload,
      };
    case GET_DATATABLE:
      return {
        ...state,
        tableData: action.payload
      };
    case GET_FULL_DATATABLE:
      return {
        ...state,
        tableData: action.payload,
        fullTableData: action.payload
      };
    case SET_VARIABLE:
      return {
        ...state,
        selectedVariable: action.payload[0],
        selectedVariableColor: action.payload[1],
        visualization: 'bubblemap'
      };
    case SET_YEAR:
      return {
        ...state,
        selectedYear: action.payload
      };
    case SET_VISUALIZATION:
      return {
        ...state,
        visualization: action.payload
      };
    default:
      return state;
  }
};

import {
  GET_ADV_IND_DATA,
  GET_HOTEL_PIPELINE_DATA,
  GET_VARIABLE_AVG,
  GET_TOP_VARIABLE_DATA,
  GET_TOP_VARIABLE_LIST,
  GET_VARIABLE_VIS_1,
  GET_VARIABLE_VIS_2
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_ADV_IND_DATA:
      return {
        ...state,
        advIndData: action.payload
      };
    case GET_HOTEL_PIPELINE_DATA:
      return {
        ...state,
        hotelPipelineData: action.payload
      };
    case GET_VARIABLE_AVG:
      return {
        ...state,
        variableAvg: action.payload
      };
    case GET_TOP_VARIABLE_DATA:
      return {
        ...state,
        topVariableData: action.payload
      };
    case GET_TOP_VARIABLE_LIST:
      return {
        ...state,
        topVariableList: action.payload
      };
    case GET_VARIABLE_VIS_1:
      return {
        ...state,
        variableVis1: action.payload
      };
    case GET_VARIABLE_VIS_2:
      return {
        ...state,
        variableVis2: action.payload
      };
    default:
      return state;
  }
};

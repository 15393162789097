import React, { useContext } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';
import DashboardContext from '../../context/dashboard/dashboardContext';
import { renderTooltipContent } from './../../components/LandingPage/CustomTooltip';

const GenericUSRevparGrowthChart = () => {
  const dashboardContext = useContext(DashboardContext);
  const { usRevparData } = dashboardContext;
  const data = [];
  usRevparData.forEach((item, i, self) => {
    let year = item['year'];
    let actual = Math.round(item['Actual RevPAR Growth'] * 1e3) / 1e1;
    let forecasted = Math.round(item['Forecasted RevPAR Growth'] * 1e3) / 1e1;
    data.push({
      year: year,
      'Actual RevPAR Growth': actual,
      'Forecasted RevPAR Growth': forecasted,
    });
  });

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{ top: 20, right: 80, bottom: 20, left: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <ReferenceLine y={0} stroke="#000" />
        <XAxis
          dataKey="year"
          height={80}
          interval={0}
          angle={-45}
          textAnchor="end"
        />
        <YAxis
          type="number"
          domain={['auto', 'auto']}
          label={{
            value: 'RevPAR Growth (%)',
            angle: -90,
            position: 'insideLeft',
          }}
        />
        <Tooltip content={renderTooltipContent} />
        <Legend />
        <Line
          type="monotone"
          dataKey="Actual RevPAR Growth"
          stroke="#000066"
          strokeWidth="2"
        />
        <Line
          type="monotone"
          dataKey="Forecasted RevPAR Growth"
          stroke="#a18d50"
          strokeWidth="2"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
export default GenericUSRevparGrowthChart;

import React, { useContext } from 'react';
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Markers,
  Marker,
} from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import { interpolateGreens, interpolateReds } from 'd3-scale-chromatic';
import tooltip from 'wsdm-tooltip';
import DashboardContext from '../../context/dashboard/dashboardContext';
import { Link } from 'react-router-dom';
import TimeSlider from './TimeSlider';
// import us_revpar_growth from './static/revpar-growth.json';
import Information16 from '@carbon/icons-react/lib/information/16';
import Radio16 from '@carbon/icons-react/lib/radio-button--checked/16';

const wrapperStyles = {
  width: '100%',
  maxWidth: 980,
  margin: '0 auto',
};

const AlbersUSA = (props) => {
  const min = 2004;
  const max = 2021;
  const tip = tooltip({
    className: 'custom-tooltip',
    styles: { border: '1px solid rgb(204, 204, 204)' },
  });

  const getVariableId = (variable_rank, variableData) => {
    return variableData[variable_rank].id;
  };
  const getVariableName = (variable_rank, variableData) => {
    return variableData[variable_rank].name;
  };
  const getVariableLongName = (variable_rank, variableData) => {
    return variableData[variable_rank].l_name;
  };
  const getVariableCorelation = (variable_rank, variableData) => {
    return variableData[variable_rank].coef;
  };
  const dashboardContext = useContext(DashboardContext);
  const {
    variableData,
    marketData,
    selectedVariable,
    selectedYear,
    flipMenuStatus,
  } = dashboardContext;
  // const marketData = require('./static/market-data.json');
  // console.log(marketData);

  const displayedVar = getVariableId(selectedVariable, variableData);
  // console.log(displayedVar);
  const displayedVarName = getVariableName(selectedVariable, variableData);
  const displayedVarLongName = getVariableLongName(
    selectedVariable,
    variableData
  );
  const corelation = getVariableCorelation(selectedVariable, variableData);

  const handleMouseMove = (geography, evt) => {
    corelation > 0
      ? tip.show(`
      <div class="tooltip-inner">
      <p><strong>${geography.name}</strong> </p>
    <p>
        ${displayedVarName}: Rank ${
          geography['variables'][displayedVar][selectedYear.toString()][1]
        } out of 50</p>
    <p>
    Revpar growth: ${
      Math.round(
        geography['variables']['revpar-growth'][selectedYear.toString()][0] *
          100
      ).toFixed(1) + '%'
    } </p>
      </div>
    `)
      : tip.show(`
        <div class="tooltip-inner">
        <p><strong>${geography.name}</strong></p>
      <p>
          ${displayedVarName}: Rank ${
          36 - geography['variables'][displayedVar][selectedYear.toString()][1]
        } out of 50</p>
      <p>
      Revpar growth: ${
        Math.round(
          geography['variables']['revpar-growth'][selectedYear.toString()][0] *
            100
        ).toFixed(1) + '%'
      }</p>
        </div>
      `);
    tip.position({ pageX: evt.pageX, pageY: evt.pageY });
  };

  const handleMouseLeave = () => {
    tip.hide();
  };
  const [minValue, maxValue] = [
    variableData[selectedVariable].min - 0.001,
    variableData[selectedVariable].max + 0.001,
  ];
  const cityScale = scaleLinear().domain([minValue, maxValue]).range([4, 40]);

  // const min_revpar_growth = us_revpar_growth[selectedYear.toString()].min;
  // const max_revpar_growth = us_revpar_growth[selectedYear.toString()].max;
  // const avg_revpar_growth = us_revpar_growth[selectedYear.toString()].average;
  // const color = scaleLinear()
  //   .domain([min_revpar_growth, avg_revpar_growth, max_revpar_growth])
  //   .range(['#F8696B', '#FFEB84', '#63BE7B']);

  const positiveColor = scaleLinear()
    .domain([minValue, maxValue])
    .range([0.3, 1]);
  const negativeColor = scaleLinear()
    .domain([minValue, maxValue])
    .range([0.3, 1]);

  return (
    <div className="bx--row">
      {/* <div className="bx--col-md-1 bx--col-lg-1" /> */}

      <div
        className="bx--col-md-4 bx--col-lg-4"
        style={{ left: '20px', position: 'relative' }}
      >
        <h4 style={{ paddingTop: '10px' }}>{displayedVarLongName}</h4>
        <p style={{ paddingTop: '10px' }}>
          Correlation:{' '}
          {corelation > 0 ? (
            <>
              Positive <Radio16 className="positioned-svg" fill="green" />
            </>
          ) : (
            <>
              Negative <Radio16 className="positioned-svg" fill="red" />
            </>
          )}
        </p>
        <p style={{ paddingTop: '10px' }}>
          Impactful level: Rank {selectedVariable + 1} out of 35{' '}
        </p>
        <p style={{ paddingTop: '10px', paddingBottom: '20px' }}>
          {variableData[selectedVariable].description}
        </p>
        <div>
          <Link style={{ marginRight: '20px' }} onClick={flipMenuStatus}>
            Back
          </Link>
          {/* <Link to="/variable">Learn More</Link> */}
        </div>
        <div style={{ paddingTop: '30%' }}>
          <p>
            <Information16 className="positioned-svg" /> The size and the color
            gradient of the bubble both indicate the strength of the variable
            for the market. The larger the size (the darker the color of the
            bubble), the larger the variable value.
          </p>
          {corelation > 0 ? (
            <p>
              Green means the variable is positively correlated to the RevPAR
              growth overall. The higher the rank of the variable to the market,
              the more significantly the variable contributes the growth of
              RevPAR for the market.
            </p>
          ) : (
            <p>
              Red means this variable is negatively correlated to the RevPAR
              growth overall. The lower the rank of the variable to the market,
              the worse the variable impacts the growth of RevPAR for the
              market.
            </p>
          )}
        </div>
      </div>

      <div className="bx--col-md-11 bx--col-lg-11">
        <div style={wrapperStyles}>
          <ComposableMap
            projection="albersUsa"
            projectionConfig={{
              scale: 1000,
            }}
            width={980}
            height={551}
            style={{
              width: '100%',
              height: 'auto',
            }}
          >
            <ZoomableGroup disablePanning>
              <Geographies
                geography={require('./static/states.json')}
                disableOptimization
              >
                {(geographies, projection) =>
                  geographies.map((geography, i) => {
                    return (
                      <Geography
                        key={`state-${geography.properties.ID_1}`}
                        cacheId={`state-${geography.properties.ID_1}`}
                        round
                        geography={geography}
                        projection={projection}
                        style={{
                          default: {
                            fill: 'white',
                            stroke: '#607D8B',
                            strokeWidth: 0.75,
                            outline: 'none',
                          },
                          hover: {
                            fill: 'white',
                            stroke: '#607D8B',
                            strokeWidth: 0.75,
                            outline: 'none',
                          },
                          pressed: {
                            fill: 'white',
                            stroke: '#607D8B',
                            strokeWidth: 0.75,
                            outline: 'none',
                          },
                        }}
                      />
                    );
                  })
                }
              </Geographies>
              <Markers>
                {marketData.map((city, i) => (
                  <Marker
                    key={i}
                    marker={city}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                  >
                    <circle
                      cx={0}
                      cy={0}
                      r={cityScale(
                        city['variables'][displayedVar][
                          selectedYear.toString()
                        ][0]
                      )}
                      // r={5}
                      fill={(corelation >= 0
                        ? interpolateGreens(
                            positiveColor(
                              city['variables'][displayedVar][
                                selectedYear.toString()
                              ][0]
                            )
                          )
                        : interpolateReds(
                            negativeColor(
                              city['variables'][displayedVar][
                                selectedYear.toString()
                              ][0]
                            )
                          )
                      )
                      // fill={(corelation >= 0
                      //   ? interpolateGreens(
                      //       4
                      //     )
                      //   : interpolateReds(
                      //       4
                      //     )
                      // )
                        .replace(')', ', 0.7)')
                        .replace('rgb', 'rgba')}
                      strokeWidth="0"
                    />
                  </Marker>
                ))}
              </Markers>
            </ZoomableGroup>
          </ComposableMap>
          <TimeSlider min={min} max={max} />
        </div>
      </div>
    </div>
  );
};

export default AlbersUSA;

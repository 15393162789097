import React, { useContext } from 'react';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import {
  SliderRail,
  Handle,
  Track,
  Tick
} from '../../components/LandingPage/SliderComponents';
import DashboardContext from '../../context/dashboard/dashboardContext';

const TimeSlider = props => {
  const dashboardContext = useContext(DashboardContext);
  const { setYear, selectedYear } = dashboardContext;
  const { min, max } = props;
  const oneYear = 1;
  const sliderStyle = {
    position: 'relative',
    width: '100%'
  };

  return (
    <div style={{ margin: '5% auto', height: 120, width: '70%' }}>
      <Slider
        mode={1}
        step={oneYear}
        domain={[+min, +max]}
        rootStyle={sliderStyle}
        onChange={setYear}
        values={[+selectedYear]}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div>
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={[+min, +max]}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div>
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks>
          {({ ticks }) => (
            <div>
              {ticks.map(tick => (
                <Tick key={tick.id} tick={tick} count={ticks.length} />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </div>
  );
};

export default TimeSlider;

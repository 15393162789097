import React, { useContext } from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from 'recharts';
import MarketpageContext from '../../context/marketpage/marketpageContext';
import { renderTooltipContent } from './../../components/LandingPage/CustomTooltip';

const NewChart = ({ data, topVar, subVar, marketType }) => {
  // console.log(topVar);
  // console.log(subVar);
  const marketpageContext = useContext(MarketpageContext);
  const { variableVis2 } = marketpageContext;
  // console.log(topVar);
  // console.log(subVar);
  // console.log(variableVis2.data[subVar[0]]);
  // const color = ['#0062ff', '#ef7e32', '#f58742', '#32a852'];
  const color = ['#000066', '#a18d50', '#9f1853', '#375623'];
  // if (topVar === 'us-revpar-forecast' || topVar === 'advanced-industry-share') {
  if (topVar === 'us-revpar-forecast' || topVar === 'prior-yr-us-revpar-forecast') {
    return <></>;
  } else {
    return (
      <>
      {/* deprecated */}
        {topVar === 'business-leisure-mix-index' && (
          <div
            style={{
              paddingTop: '5px',
              paddingBottom: '5px',
              paddingLeft: '10px',
              border: '1px solid',
              margin: '0 auto',
              // textAlign: 'center',
              width: '350px',
            }}
          >
            <p>RevPAR Growth CAGR 2005-2019: </p>
            <p> - Business-focused markets: 3.6%</p>
            <p> - Leisure-focused markets: 2.5%</p>
            <p> - Mixed-focused markets: 3.7%</p>
            <p>Current market type: {marketType} </p>
          </div>
        )}
        {topVar === 'advanced-industry-share' && (
          <div style={{ paddingTop: '50px' }}>
            <p> </p>
          </div>
        )}
        <ResponsiveContainer width="100%" height={350}>
          <ComposedChart
            syncId={topVar}
            data={data}
            margin={{ top: 20, right: 80, bottom: 20, left: 20 }}
          >
            <XAxis dataKey="year" />
            <YAxis>
              <Label
                value={variableVis2[subVar[0]]['yaxis']}
                position="insideLeft"
                angle={-90}
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            {variableVis2[subVar[0]]['name'].includes('%') ? (
              <Tooltip content={renderTooltipContent} />
            ) : (
              <Tooltip />
            )}

            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            {subVar.map((variable, index) => {
              return variableVis2[variable]['type'] === 'line' ? (
                <Line
                  name={variableVis2[variable].name}
                  type="monotone"
                  dataKey={variable}
                  stroke={color[index]}
                  strokeWidth="2"
                  isAnimationActive={true}
                />
              ) : (
                <Bar
                  barSize={20}
                  name={variableVis2[variable].name}
                  dataKey={variable}
                  fill={color[index]}
                  isAnimationActive={true}
                />
              );
            })}
          </ComposedChart>
        </ResponsiveContainer>
      </>
    );
  }
};

export default NewChart;

import React from 'react';
import { Content } from 'carbon-components-react/lib/components/UIShell';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Content id="main-content">
      <div className="bx--grid bx--grid--full-width">
        <div className="bx--row">
          <div className="bx--col-lg-16">
            <h1 className="landing-page__heading">Page Not Found</h1>
            <p style={{ padding: '20px', paddingLeft: 0 }}>
              We are sorry but the page you are looking for does not exist.
            </p>
            <p>
              Back to <Link to={'/'}>Home Page</Link>.
            </p>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default NotFound;

import React from 'react';

const Footer = ({ page }) => {
  if (page === 'login') {
    return (
      <div
        style={{
          textAlign: 'center',
          position: 'fixed',
          bottom: '10px',
          color: 'grey',
          width: '100%',
          margin: '0 auto',
        }}
      >
        <span>IBM Research | © 2022 IBM Corporation</span>
      </div>
    );
  } else {
    return (
      <div
        style={{
          paddingTop: '20px',
          textAlign: 'center',
          color: 'grey',
        }}
      >
        <span>IBM Research | © 2022 IBM Corporation</span>
      </div>
    );
  }
};
export default Footer;

import { USER_LOADED, AUTH_ERROR, LOGOUT } from '../types';
export default (state, action) => {
  switch (action.type) {
    case USER_LOADED:
      return {
        ...state,
        loading: false,
        isAuthenticated: action.payload.logged,
        user: action.payload.loggedInAs
      };
    case LOGOUT:
    case AUTH_ERROR:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null
      };

    default:
      return state;
  }
};

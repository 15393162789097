import React, { useReducer } from 'react';
import axios from 'axios';
import AuthContext from './authContext';
import authReducer from './authReducer';
import { USER_LOADED, AUTH_ERROR, LOGOUT } from '../types';

const AuthState = props => {
  const initialState = {
    isAuthenticated: false,
    loading: process.env.NODE_ENV === 'development' ? false : true, // true
    user: null
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  // Load User
  const loadUser = async () => {
    try {
      // console.log('loadUser');
      const res = await axios.get('/auth/logged');
      // console.log(res.data);
      dispatch({
        type: USER_LOADED,
        payload: res.data
      });
    } catch (err) {
      dispatch({ type: AUTH_ERROR });
    }
  };
  const logout = async () => {
    try {
      await axios.get('/auth/logout');
      dispatch({ type: LOGOUT });
    } catch (err) {
      dispatch({ type: LOGOUT });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        logout,
        loadUser
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;

import React, { useContext, useState, useEffect } from 'react';
import DashboardContext from '../../context/dashboard/dashboardContext';
import MarketpageContext from '../../context/marketpage/marketpageContext';

import { Checkbox } from 'carbon-components-react';
import GoogleMap from './GoogleMap';

const SupplyPipeline = ({ marketId }) => {
  const dashboardContext = useContext(DashboardContext);
  const { tableData, marketData } = dashboardContext;
  const selectedMarketName = tableData.find((m) => m.id === marketId).name;
  const marketpageContext = useContext(MarketpageContext);
  const { hotelPipelineData } = marketpageContext; // market name
  const marketHotelPipelineData = hotelPipelineData[marketId];
  // console.log(marketHotelPipelineData)
  const marketCoordinates = marketData.find((m) => m.id === marketId)
    .coordinates;
  const [ehp, setEhp] = useState(true);
  const [uc, setUc] = useState(true);
  const [ep, setEp] = useState(true);
  const [sn12m, setSn12m] = useState(true);
  const states = [ehp, uc, ep, sn12m];

  const [c, setC] = useState(true);
  const [e, setE] = useState(true);
  const [l, setL] = useState(true);
  const [m, setM] = useState(true);
  const [u, setU] = useState(true);
  const [um, setUM] = useState(true);
  const [uu, setUU] = useState(true);
  const states2 = [c, e, l, m, u, um, uu];

  const handleAllChecked = (isChecked) => {
    setEhp(isChecked);
    setUc(isChecked);
    setEp(isChecked);
    setSn12m(isChecked);
  };

  const handleAllCheckedChainScale = (isChecked) => {
    setC(isChecked);
    setE(isChecked);
    setL(isChecked);
    setM(isChecked);
    setU(isChecked);
    setUM(isChecked);
    setUU(isChecked);
  };

  useEffect(() => {
    setEhp(true);
    setUc(true);
    setEp(true);
    setSn12m(true);
    setC(true);
    setE(true);
    setL(true);
    setM(true);
    setU(true);
    setUM(true);
    setUU(true);
  }, [marketId]);

  return (
    <div>
      <h2 style={{ paddingTop: '50px', paddingBottom: '0px' }}>
        Hotel Supply Pipeline for {selectedMarketName}
      </h2>
      <div className="bx--row" style={{ paddingTop: '30px' }}>
        <div className="bx--col-md-4 bx--col-lg-4">
          <div className="checkbox">
            <div className="checkbox-text">
              <Checkbox
                defaultChecked
                {...{
                  labelText: 'Select All Phases',
                }}
                onChange={handleAllChecked}
                id="select-all"
              />
            </div>
          </div>
          <div className="checkbox">
            <div className="checkbox-text">
              <Checkbox
                checked={ehp}
                {...{
                  labelText: 'Existing Host Properties',
                }}
                onChange={(isChecked) => {
                  setEhp(isChecked);
                }}
                id="ehp"
              />
            </div>

            <div>
              <img
                src="https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                alt="Existing Host Properties"
                width="20"
              />
            </div>
          </div>
          <div className="checkbox">
            <div className="checkbox-text">
              <Checkbox
                checked={uc}
                {...{
                  labelText: 'Under Construction',
                }}
                onChange={(isChecked) => {
                  setUc(isChecked);
                }}
                id="uc"
              />
            </div>

            <div>
              {' '}
              <img
                src="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                alt="Under Construction"
                width="20"
              />
            </div>
          </div>
          <div className="checkbox">
            <div className="checkbox-text">
              <Checkbox
                checked={sn12m}
                {...{
                  labelText: 'Start Next 12 Months',
                }}
                onChange={(isChecked) => {
                  setSn12m(isChecked);
                }}
                id="sn12m"
              />
            </div>{' '}
            <div>
              <img
                src="https://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
                alt="Start Next 12 Months"
                width="20"
              />
            </div>
          </div>
          <div className="checkbox">
            <div className="checkbox-text">
              <Checkbox
                checked={ep}
                {...{
                  labelText: 'Early Planning',
                }}
                onChange={(isChecked) => {
                  setEp(isChecked);
                }}
                id="ep"
              />
            </div>

            <div>
              {' '}
              <img
                src="https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                alt="Early Planning"
                width="20"
              />
            </div>
          </div>
          <hr
            style={{
              color: '#ccc',
              backgroundColor: 'white',
              height: 1,
            }}
          />
          <div className="checkbox">
            <div className="checkbox-text">
              <Checkbox
                defaultChecked
                {...{
                  labelText: 'Select All Chain Scales',
                }}
                onChange={handleAllCheckedChainScale}
                id="select-all-chain-scale"
              />
            </div>
          </div>
          <div className="checkbox">
            <div className="checkbox-text">
              <Checkbox
                checked={l}
                {...{
                  labelText: 'Luxury',
                }}
                onChange={(isChecked) => {
                  setL(isChecked);
                }}
                id="Luxury"
              />
            </div>
          </div>
          <div className="checkbox">
            <div className="checkbox-text">
              <Checkbox
                checked={uu}
                {...{
                  labelText: 'Upper Upscale',
                }}
                onChange={(isChecked) => {
                  setUU(isChecked);
                }}
                id="Upper-Upscale"
              />
            </div>
          </div>
          <div className="checkbox">
            <div className="checkbox-text">
              <Checkbox
                checked={u}
                {...{
                  labelText: 'Upscale',
                }}
                onChange={(isChecked) => {
                  setU(isChecked);
                }}
                id="upscale"
              />
            </div>
          </div>
          <div className="checkbox">
            <div className="checkbox-text">
              <Checkbox
                checked={um}
                {...{
                  labelText: 'Upper Midscale',
                }}
                onChange={(isChecked) => {
                  setUM(isChecked);
                }}
                id="Upper-Midscale"
              />
            </div>
          </div>
          <div className="checkbox">
            <div className="checkbox-text">
              <Checkbox
                checked={m}
                {...{
                  labelText: 'Midscale',
                }}
                onChange={(isChecked) => {
                  setM(isChecked);
                }}
                id="Midscale"
              />
            </div>
          </div>
          <div className="checkbox">
            <div className="checkbox-text">
              <Checkbox
                checked={e}
                {...{
                  labelText: 'Economy',
                }}
                onChange={(isChecked) => {
                  setE(isChecked);
                }}
                id="economy"
              />
            </div>
          </div>
          <div className="checkbox">
            <div className="checkbox-text">
              <Checkbox
                checked={c}
                {...{
                  labelText: 'Independent',
                }}
                onChange={(isChecked) => {
                  setC(isChecked);
                }}
                id="independent"
              />
            </div>
          </div>
        </div>
        <div className="bx--col-md-12 bx--col-lg-12">
          <GoogleMap
            id="google-map"
            market={marketId}
            data={marketHotelPipelineData}
            center={marketCoordinates}
            filters={states}
            filters2={states2}
          />
        </div>
      </div>
    </div>
  );
};

export default SupplyPipeline;

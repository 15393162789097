import React, { useContext } from 'react';
import { OffCanvas, OffCanvasMenu, OffCanvasBody } from 'react-offcanvas';
import AlbersUSA from './AlbersUSA';
import DashboardContext from '../../context/dashboard/dashboardContext';
import SpiralCircle from './SpiralCircle';
const Canvas = () => {
  const dashboardContext = useContext(DashboardContext);
  const { isMenuOpened } = dashboardContext;

  return (
    <div style={{ backgroundColor: 'white' }}>
      <h3 style={{ paddingTop: '20px', paddingLeft: '20px' }}>
        Relative Strength and Sign of Impact of Variables
      </h3>
      <OffCanvas
        width={(document.body.clientWidth * 15) / 16}
        transitionDuration={300}
        effect={'push'}
        isMenuOpened={isMenuOpened}
        position={'right'}
      >
        <OffCanvasMenu>
          <AlbersUSA />
        </OffCanvasMenu>
        <OffCanvasBody style={{ fontSize: '30px' }}>
          <SpiralCircle />
        </OffCanvasBody>
      </OffCanvas>
    </div>
  );
};

export default Canvas;

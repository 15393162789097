import React from 'react';
export const marketInTooltip = o => {
  const style = {
    margin: 0,
    padding: 10,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    whiteSpace: 'nowrap'
  };
  const { payload, label } = o;
  // console.log(payload);
  return (
    <div className="customized-tooltip-content" style={style}>
      <p className="total">{`${label}`}</p>
      <ul className="list">
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{ color: entry.color, paddingTop: '10px' }}
          >
            {entry.name === 'Min' &&
              `${entry.name}: ${entry.value} (${entry.payload.minMarket})`}
            {entry.name === 'Max' &&
              `${entry.name}: ${entry.value} (${entry.payload.maxMarket})`}
            {entry.name === 'Avg' && `${entry.name}: ${entry.value}`}
          </li>
        ))}
      </ul>
    </div>
  );
};

export const renderTooltipContent = o => {
  const style = {
    margin: 0,
    padding: 10,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    whiteSpace: 'nowrap'
  };
  const { payload, label } = o;

  return (
    <div className="customized-tooltip-content" style={style}>
      <p className="total">{`${label}`}</p>
      <ul className="list">
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{ color: entry.color, paddingTop: '10px' }}
          >
            {`${entry.name}: ${entry.value}%`}
          </li>
        ))}
      </ul>
    </div>
  );
};

import React, { useReducer } from 'react';
import MarketpageContext from './marketpageContext';
import MarketpageReducer from './marketpageReducer';
import axios from 'axios';
import {
  GET_ADV_IND_DATA,
  GET_HOTEL_PIPELINE_DATA,
  GET_VARIABLE_AVG,
  GET_TOP_VARIABLE_DATA,
  GET_TOP_VARIABLE_LIST,
  GET_VARIABLE_VIS_1,
  GET_VARIABLE_VIS_2,
} from '../types';

const MarketpageState = (props) => {
  // const varAvgData = require('./../../contents/MarketPage/static/variable-avg.json');
  // const hotelPipelineData = require('./../../contents/MarketPage/static/hotel-pipeline.json');
  // const topVariableData = require('./../../contents/MarketPage/static/market-top-variables.json');
  // const topVariableList = require('./../../contents/MarketPage/static/market-top-variables-list.json');
  // const variableVis1 = require('./../../contents/MarketPage/static/variable-vis-1.json');
  // const variableVis2 = require('./../../contents/MarketPage/static/variable-vis-2.json');
  // const advIndData = require('./../../contents/MarketPage/static/adv-ind.json');
  const initialState = {
    variableAvg: null,
    topVariableData: null,
    topVariableList: null,
    variableVis1: null,
    variableVis2: null,
    hotelPipelineData: null,
    advIndData: null,
  };
  const [state, dispatch] = useReducer(MarketpageReducer, initialState);

  // const api_prefix = '/api/v1/marketpage';
  const api_prefix =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000/api/v5/marketpage'
      : '/api/marketpage';

  const getAdvIndData = async () => {
    if (state.advIndData !== null) return;
    // console.log('getAdvIndData');
    try {
      const res = await axios.get(api_prefix + '/adv-ind');
      // console.log(res);

      dispatch({
        type: GET_ADV_IND_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getHotelPipelineData = async () => {
    if (state.hotelPipelineData !== null) return;
    // console.log(api_prefix + '/hotel-pipeline');
    // console.log('getHotelPipelineData');
    try {
      const res = await axios.get(api_prefix + '/hotel-pipeline');
      // console.log(res);

      dispatch({
        type: GET_HOTEL_PIPELINE_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getVariableAvg = async () => {
    if (state.variableAvg !== null) return;
    // console.log('getVariableAvg');
    // console.log(api_prefix + '/variable-avg');

    try {
      const res = await axios.get(api_prefix + '/variable-avg');
      // console.log(res);

      dispatch({
        type: GET_VARIABLE_AVG,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getTopVariableData = async () => {
    if (state.topVariableData !== null) return;
    // console.log(api_prefix + '/market-top-variables');

    console.log('getTopVariableData');
    try {
      const res = await axios.get(api_prefix + '/market-top-variables');
      // console.log(res);

      dispatch({
        type: GET_TOP_VARIABLE_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getTopVariableList = async () => {
    if (state.topVariableList !== null) return;
    // console.log(api_prefix + '/market-top-variables-list');

    // console.log('getTopVariableList');
    try {
      const res = await axios.get(api_prefix + '/market-top-variables-list');
      // console.log(res);

      dispatch({
        type: GET_TOP_VARIABLE_LIST,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getVariableVis1 = async () => {
    if (state.variableVis1 !== null) return;
    // console.log(api_prefix + '/variable-vis-1');

    // console.log('getVar1');
    try {
      const res = await axios.get(api_prefix + '/variable-vis-1');
      // console.log(res);

      dispatch({
        type: GET_VARIABLE_VIS_1,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getVariableVis2 = async () => {
    if (state.variableVis2 !== null) return;
    // console.log(api_prefix + '/variable-vis-2');

    // console.log('getVar2');
    try {
      const res = await axios.get(api_prefix + '/variable-vis-2');
      // console.log(res);

      dispatch({
        type: GET_VARIABLE_VIS_2,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MarketpageContext.Provider
      value={{
        variableAvg: state.variableAvg,
        topVariableData: state.topVariableData,
        topVariableList: state.topVariableList,
        variableVis1: state.variableVis1,
        variableVis2: state.variableVis2,
        advIndData: state.advIndData,
        hotelPipelineData: state.hotelPipelineData,
        getAdvIndData,
        getHotelPipelineData,
        getVariableAvg,
        getTopVariableData,
        getTopVariableList,
        getVariableVis1,
        getVariableVis2,
      }}
    >
      {props.children}
    </MarketpageContext.Provider>
  );
};

export default MarketpageState;

import React, { Component } from 'react';

class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.onScriptLoad = this.onScriptLoad.bind(this);
    this.searchBoxLoad = this.searchBoxLoad.bind(this);
    this.setMarkers = this.setMarkers.bind(this);
    this.gmarkers = [];
    this.map = null;
    this.searchBox = null;
  }

  setMarkers() {
    this.gmarkers = [];
    const color_pin_mapping = {
      'Existing Host Properties': 'blue',
      'Under Construction': 'red',
      'Early Planning': 'green',
      'Start Next 12 Months': 'yellow',
    };
    var marker, i;
    for (i = 0; i < this.props.data.length; i++) {
      let centerLat = this.props.data[i]['geocoder_latitude'];
      let centerLong = this.props.data[i]['geocoder_longitude'];
      let category = this.props.data[i]['Project Stage'];
      let chainScale = this.props.data[i]['Unbranded Chain Scale'];
      let color = color_pin_mapping[category];
      //
      let content =
        category === 'Existing Host Properties'
          ? `<p><strong>${this.props.data[i]['Hotel Name']}</strong></p>
      <p>Rooms: ${this.props.data[i]['Rooms']}</p> 
      <p>Chain Scale: ${this.props.data[i]['Unbranded Chain Scale']}</p>`
          : `<p><strong>${this.props.data[i]['Hotel Name']}</strong></p>
      <p>Rooms: ${this.props.data[i]['Rooms']}</p> 
      <p>Chain Scale: ${this.props.data[i]['Unbranded Chain Scale']}</p> 
      <p>Projected Opening Date: ${this.props.data[i]['Projected Opening Date']}</p> 
      <p>Tract: ${this.props.data[i]['Tract']}</p> `;
      marker = new window.google.maps.Marker({
        position: {
          lat: centerLat,
          lng: centerLong,
        },
        category: category,
        chainScale: chainScale,
        icon: {
          url:
            'https://maps.google.com/mapfiles/ms/icons/' + color + '-dot.png',
        },
        map: this.map,
      });
      this.gmarkers.push(marker);
      const infowindow = new window.google.maps.InfoWindow({
        content: content,
      });
      window.google.maps.event.addListener(
        marker,
        'click',
        (function (marker, i) {
          return function () {
            this.map.setZoom(Math.max(15, this.map.getZoom()));
            this.map.setCenter(marker.getPosition());
            infowindow.open(this.map, marker);
          };
        })(marker, i)
      );
    }
  }

  switchMarketLoad() {
    for (let i = 0; i < this.gmarkers.length; i++) {
      let marker = this.gmarkers[i];
      marker.setVisible(false);
      marker = null;
    }
    document.getElementById('pac-input').value = '';
    this.map.setCenter({
      lat: this.props.center[1],
      lng: this.props.center[0],
    });
    this.map.setZoom(10);
    this.setMarkers();
  }

  searchBoxLoad() {
    // console.log(
    //   this.map.controls[window.google.maps.ControlPosition.TOP_LEFT].length
    // );
    var input = document.getElementById('pac-input');
    if (!this.searchBox) {
      this.searchBox = new window.google.maps.places.SearchBox(input);
    }
    // console.log('create searchBox');
    this.map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
    // console.log(
    //   this.map.controls[window.google.maps.ControlPosition.TOP_LEFT].length
    // );

    var searchedPlaces = [];
    var map = this.map;
    var searchBox = this.searchBox;
    window.google.maps.event.addListener(
      this.map,
      'bounds_changed',
      function () {
        searchBox.setBounds(this.getBounds());
      }
    );
    window.google.maps.event.addListener(
      this.searchBox,
      'places_changed',
      function () {
        var places = this.getPlaces();
        // console.log(places);
        if (places.length === 0) {
          return;
        }

        // Clear out the old markers.
        searchedPlaces.forEach(function (marker) {
          marker.setMap(null);
        });
        searchedPlaces = [];

        // For each place, get the icon, name and location.
        var bounds = new window.google.maps.LatLngBounds();
        places.forEach(function (place) {
          if (!place.geometry) {
            // console.log('Returned place contains no geometry');
            return;
          }
          var icon = {
            url: place.icon,
            size: new window.google.maps.Size(71, 71),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(17, 34),
            scaledSize: new window.google.maps.Size(25, 25),
          };

          // Create a marker for each place.
          searchedPlaces.push(
            new window.google.maps.Marker({
              map: map,
              icon: icon,
              title: place.name,
              position: place.geometry.location,
            })
          );

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      }
    );
  }
  onScriptLoad() {
    this.map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      {
        center: {
          lat: this.props.center[1],
          lng: this.props.center[0],
        },
        zoom: 10,
        gestureHandling: 'greedy',
        streetViewControl: false,
        minZoom: 9,
      }
    );
    this.searchBoxLoad();
    this.setMarkers();
  }
  // c, e, l, m, u, um, uu
  componentDidUpdate(prevProps) {
    const category_mapping = {
      'Existing Host Properties': this.props.filters[0],
      'Under Construction': this.props.filters[1],
      'Early Planning': this.props.filters[2],
      'Start Next 12 Months': this.props.filters[3],
      Independent: this.props.filters2[0],
      Economy: this.props.filters2[1],
      Luxury: this.props.filters2[2],
      Midscale: this.props.filters2[3],
      Upscale: this.props.filters2[4],
      'Upper Midscale': this.props.filters2[5],
      'Upper Upscale': this.props.filters2[6],
    };

    if (prevProps.market !== this.props.market) {
      // console.log('market not equal');
      // this.onScriptLoad();
      this.switchMarketLoad();
    }

    if (
      prevProps.filters !== this.props.filters ||
      prevProps.filters2 !== this.props.filters2
    ) {
      // console.log('filters not equal');
      for (let i = 0; i < this.gmarkers.length; i++) {
        let marker = this.gmarkers[i];
        if (
          category_mapping[marker.category] &&
          category_mapping[marker.chainScale]
        ) {
          marker.setVisible(true);
        } else {
          marker.setVisible(false);
        }
      }
    }
  }

  componentDidMount() {
    if (!window.google) {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      //AIzaSyCPLsMGlT2xV9TOagOztN8i0QwIT4M0SDM
      s.src = `https://maps.google.com/maps/api/js?key=AIzaSyCPLsMGlT2xV9TOagOztN8i0QwIT4M0SDM&libraries=places&callback=initAutocomplete`;
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      // Below is important.
      //We cannot access google.maps until it's finished loading
      s.addEventListener('load', (e) => {
        this.onScriptLoad();
        // this.searchBoxLoad();
      });
    } else {
      this.onScriptLoad();
      // this.searchBoxLoad();
    }
  }

  render() {
    return (
      <>
        <input
          id="pac-input"
          class="controls"
          type="text"
          placeholder="Type address here..."
        />
        <div
          style={{ height: '600px', width: '100%' }}
          filters={this.props.filters}
          filters2={this.props.filters2}
          id={this.props.id}
        />
      </>
    );
  }
}

export default GoogleMap;

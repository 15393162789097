import React, { Component } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';

import './app.scss';
import HorizonHeader from './components/HorizonHeader';
import LandingPage from './contents/LandingPage';
import MarketPage from './contents/MarketPage';
import AdminPage from './contents/AdminPage';
// import VariablePage from './contents/VariablePage';
import PostCovidNlpPage from './contents/PostCovidNlpPage';
import NotFound from './components/NotFound';
import Login from './components/auth/Login';
import AuthState from './context/auth/AuthState';
import DashboardState from './context/dashboard/DashboardState';
import MarketpageState from './context/marketpage/MarketpageState';
import VariablepageState from './context/variablepage/VariablepageState';
import PostcovidpageState from './context/postcovidpage/PostscovidpageState';
import PrivateRoute from './components/routing/PrivateRoute.js';
import HttpsRedirect from 'react-https-redirect';
import createHistory from 'history/createBrowserHistory';

const history = createHistory();
class App extends Component {
  render() {
    return process.env.NODE_ENV === 'development' ? (
      <HttpsRedirect>
        <BrowserRouter history={history}>
          <AuthState>
            <PostcovidpageState>
              <VariablepageState>
                <MarketpageState>
                  <DashboardState>
                    <HorizonHeader />
                    {/* <ScrollToTop /> */}
                    <Switch>
                      <Route exact path="/" component={LandingPage} />
                      <Route exact path="/login" component={Login} />
                      <Route path="/market/:id" component={MarketPage} />
                      <Route path="/admin" component={AdminPage} />
                      <Route path="/post-covid" component={PostCovidNlpPage} />
                      {/* <Route path="/variable/:id" component={VariablePage} /> */}
                      <Route path="/404" component={NotFound} />
                      <Redirect to="/404" />
                    </Switch>
                  </DashboardState>
                </MarketpageState>
              </VariablepageState>
            </PostcovidpageState>
          </AuthState>
        </BrowserRouter>
      </HttpsRedirect>
    ) : (
      // </HttpsRedirect>
      // <HttpsRedirect>
      <BrowserRouter>
        <AuthState>
          <VariablepageState>
            <MarketpageState>
              <DashboardState>
                <HorizonHeader />
                <Switch>
                  <PrivateRoute exact path="/" component={LandingPage} />
                  <Route exact path="/login" component={Login} />
                  <PrivateRoute path="/market/:id" component={MarketPage} />
                  <PrivateRoute path="/admin" component={AdminPage} />
                  <PrivateRoute
                    path="/post-covid"
                    component={PostCovidNlpPage}
                  />
                  <PrivateRoute component={NotFound} />
                </Switch>
              </DashboardState>
            </MarketpageState>
          </VariablepageState>
        </AuthState>
      </BrowserRouter>
      // </HttpsRedirect>
    );
  }
}

export default App;

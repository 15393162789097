import React from 'react';
import { Button, Form } from 'carbon-components-react';
import logo from './static/Host_logo_transparent_black.png';
import Footer from '../Footer/Footer';

// const ControlledPasswordInputApp = props => {
//   const [type, setType] = useState('password');
//   return (
//     <TextInput.ControlledPasswordInput
//       onChange={props.onChange}
//       type={type}
//       id="password"
//       placeholder="Password"
//       labelText="Password"
//       hideLabel={true}
//       light={true}
//       togglePasswordVisibility={() => {
//         type === 'password' ? setType('text') : setType('password');
//       }}
//     />
//   );
// };
const Login = () => {

  const onSubmit = e => {
    e.preventDefault();
    // console.log('Login submit');
    // console.log(email);
    // console.log(password);
  };

  const login = (
    <>
      {/* <img src={logo} alt="Logo" /> */}

      <div className="bx--col-md-3 bx--col-lg-3 login-row">
        <Form className="login-form" onSubmit={onSubmit}>
          {/* <h2 id="login-panel-title">Account Login</h2> */}
          <img className="login-img" src={logo} alt="Logo" />

          {/* <TextInput
            id="email"
            type="email"
            labelText="Email Address"
            placeholder="Email Address"
            hideLabel={true}
            light={true}
            onChange={onChange}
          />
          <ControlledPasswordInputApp onChange={onChange} />
          <Checkbox
            defaultChecked
            {...checkboxEvents}
            id="checkbox-0"
            style={{ color: '$text-04' }}
          /> */}
          <Button
            id="login-button"
            href="/auth/login"
            type="submit"
            size="small"
          >
            {/* <Button id="login-button" href="/" type="submit" size="small"> */}
            Log In
          </Button>
        </Form>
      </div>
      {/* <div className="login-footer">Powered by Host Hotels & Resorts</div> */}
    </>
  );
  return (
    <div id="full-page">
      {login} <Footer page="login" />
    </div>
  );
};

// export default Login;
export default Login;

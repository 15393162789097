import React, { useRef, useContext, useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import { Content } from 'carbon-components-react/lib/components/UIShell';
import DashboardContext from '../../context/dashboard/dashboardContext';
import AuthContext from '../../context/auth/authContext';
import Footer from '../../components/Footer/Footer';
import LandingPageContent from './LandingPageContent';
import ReactLoading from 'react-loading';

// import { Loading } from 'carbon-components-react';

const LandingPage = () => {
  const authContext = useContext(AuthContext);
  const { loading } = authContext;
  const dashboardContext = useContext(DashboardContext);
  const {
    tableData,
    usRevparData,
    segmentationData,
    marketData,
    variableData,
    variableCsv,
    getUsRevparData,
    getMarketData,
    getSegmentation,
    getVariableData,
    getFullDatatable,
    getVariableCsv,
    tooltip,
    getTooltip
  } = dashboardContext;
  const componentRef = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
    getFullDatatable();
    getSegmentation();
    getVariableCsv();
    getVariableData();
    getMarketData();
    getUsRevparData();
    getTooltip();
    // console.log(usRevparData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content id="main-content">
      {!loading &&
      tableData !== null &&
      usRevparData !== null &&
      segmentationData !== null &&
      marketData !== null &&
      variableData !== null &&
      variableCsv !== null && tooltip !== null ? (
        <>
          {/* <ReactToPrint
            trigger={() => <button>Print this out!</button>}
            content={() => componentRef.current}
            copyStyles={true}
            onBeforePrint={() => console.log('trigger')}
          /> */}
          <LandingPageContent ref={componentRef} />
        </>
      ) : (
        <ReactLoading
          className="preloader"
          type={'spin'}
          color={'#0062ff'}
          height={60}
          width={60}
        />
        // <Loading active withOverlay={true} />
      )}
      <Footer page="landing" />
    </Content>
  );
};

export default LandingPage;

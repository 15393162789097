import React, { useContext } from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import DashboardContext from '../../context/dashboard/dashboardContext';
import { renderTooltipContent } from './../../components/LandingPage/CustomTooltip';

const RevparChart = ({ marketId }) => {
  const dashboardContext = useContext(DashboardContext);
  const { marketData, tableData } = dashboardContext; // TODO: remove marketData
  const selectedMarketData = marketData.find((e) => e.id === marketId);
  const revpar_growth = selectedMarketData['variables']['revpar-growth'];
  const us_revpar_growth = selectedMarketData['variables']['us-revpar-growth'];
  const selectedMarketName = tableData.find((m) => m.id === marketId).name;

  const data = [];
  for (let i = 2004; i <= 2021; i++) {
    let s = i.toString();
    let revpar_growth_val = (revpar_growth[s][0] * 100).toFixed(1);
    let us_revpar_growth_val = (us_revpar_growth[s][0] * 100).toFixed(1);
    data.push({
      name: s,
      revpar_growth: revpar_growth_val,
      us_revpar_growth: us_revpar_growth_val,
    });
  }

  return (
    <>
      <h3 id="revpar-chart-title">
        {selectedMarketName} RevPAR Growth vs. US RevPAR Growth
      </h3>

      <ResponsiveContainer width="100%" height={350}>
        <ComposedChart
          data={data}
          margin={{ top: 20, right: 80, bottom: 20, left: 20 }}
        >
          <XAxis
            dataKey="name"
            // label={{ value: 'year', position: 'insideBottomRight', offset: -5 }}
          />
          <YAxis
            domain={[-80, 30]}
            label={{
              value: 'RevPAR Growth (%)',
              angle: -90,
              position: 'insideLeft',
            }}
          />
          <Tooltip content={renderTooltipContent} />
          <Legend />
          <CartesianGrid stroke="#f5f5f5" />
          {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
          <Bar
            name="Market RevPAR Growth"
            dataKey="revpar_growth"
            barSize={20}
            fill="#000066"
          />
          <Line
            type="monotone"
            name="US RevPAR Growth"
            dataKey="us_revpar_growth"
            stroke="#a18d50"
            strokeWidth="2"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default RevparChart;

import React, { useContext } from 'react';
import DashboardContext from '../../context/dashboard/dashboardContext';
import { useTable } from 'react-table';
import styled from 'styled-components';
import axios from 'axios';
import { Button, TextArea, Form } from 'carbon-components-react';

const api_prefix_2 =
  process.env.NODE_ENV === 'development'
    ? `http://localhost:3000/api/v4/update`
    : `/api/update`;

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <input value={value} onChange={onChange} onBlur={onBlur} />;
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

// Be sure to pass our updateMyData and the skipPageReset option
function Table({ columns, data, updateMyData, skipPageReset }) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        // use the skipPageReset option to disable page resetting temporarily
        autoResetPage: !skipPageReset,
        // updateMyData isn't part of the API, but
        // anything we put into these options will
        // automatically be available on the instance.
        // That way we can call this function from our
        // cell renderer!
        updateMyData,
      }
      // usePagination
    );

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const EditableTable = () => {
  const dashboardContext = useContext(DashboardContext);
  const { originalTableData } = dashboardContext;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Before',
        columns: [
          {
            Header: 'Market',
            accessor: 'name',
            Cell: function Cell(cell) {
              return <span>{cell.value}</span>;
            },
          },
          {
            Header: 'Rank (19-26)',
            accessor: 'rank',
            Cell: function Cell(cell) {
              return <span>{cell.value}</span>;
            },
          },
          {
            Header: 'RevPAR Growth (19-26)',
            accessor: 'revpar_growth_rate',
            Cell: function Cell(cell) {
              return <span> {Math.round(cell.value * 1000000) / 10000}%</span>;
            },
          },
          {
            Header: 'RevPAR (2026)',
            accessor: 'revpar_forecast',
            Cell: function Cell(cell) {
              return <span>${cell.value}</span>;
            },
          },
          {
            Header: 'Rank (22-26)',
            accessor: 'rank_5yr',
            Cell: function Cell(cell) {
              return <span>{cell.value}</span>;
            },
          },
          {
            Header: 'RevPAR Growth (22-26)',
            accessor: 'revpar_growth_rate_5yr',
            Cell: function Cell(cell) {
              return <span> {Math.round(cell.value * 1000000) / 10000}%</span>;
            },
          },
        ],
      },
      {
        Header: 'Adjustment',
        columns: [
          {
            Header: 'Adjustment',
            accessor: 'adjustment',
          },
        ],
      },
      {
        Header: 'After',
        columns: [
          {
            Header: 'Adjusted Rank (19-26)',
            accessor: 'adjusted_rank',
            Cell: function Cell(cell) {
              return <span>{cell.value}</span>;
            },
          },
          {
            Header: 'Adjusted RevPAR Growth (19-26)',
            accessor: 'adjusted_revpar_growth_rate',
            Cell: function Cell(cell) {
              return <span> {Math.round(cell.value * 1000000) / 10000}%</span>;
            },
          },
          {
            Header: 'Adjusted RevPAR (2026)',
            accessor: 'adjusted_revpar_forecast',
            Cell: function Cell(cell) {
              return <span>${cell.value}</span>;
            },
          },
          {
            Header: 'Adjusted Rank (22-26)',
            accessor: 'adjusted_rank_5yr',
            Cell: function Cell(cell) {
              return <span>{cell.value}</span>;
            },
          },
          {
            Header: 'Adjusted RevPAR Growth (22-26)',
            accessor: 'adjusted_revpar_growth_rate_5yr',
            Cell: function Cell(cell) {
              return <span> {Math.round(cell.value * 1000000) / 10000}%</span>;
            },
          },
        ],
      },
    ],
    []
  );

  const [data, setData] = React.useState(originalTableData);
  const [validation, setValidation] = React.useState('');
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [desc, setDesc] = React.useState('');
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  const resetData = () => setData(originalTableData);

  const calculateData = () => {
    var pass = true;
    var newData = data.slice();

    for (let d of newData) {
      var val = parseFloat(d['adjustment']);
      if (isNaN(val)) {
        setValidation(`The adjustment for ${d['name']} is not a valid number`);
        pass = false;
        break;
      }
    }
    if (!pass) return;
    setValidation('');

    // console.log(newData)
    for (let d of newData) {
      d['adjusted_revpar_growth_rate'] =
        d['revpar_growth_rate'] + parseFloat(d['adjustment']);
      d['adjusted_revpar_forecast'] =
        d['uup_revpar_2019'] *
        Math.exp(6 * Math.log(d['adjusted_revpar_growth_rate'] + 1));
      d['adjusted_revpar_forecast'] =
        Math.round(d['adjusted_revpar_forecast'] * 100) / 100;
      d['adjusted_revpar_growth_rate_5yr'] =
        Math.exp(
          Math.log(d['adjusted_revpar_forecast'] / d['uup_revpar_2020']) / 5
        ) - 1;
      d['adjusted_revpar_growth_rate_5yr'] =
        Math.round(d['adjusted_revpar_growth_rate_5yr'] * 1000000) / 1000000;
    }

    var adjusted_revpar_growth_rates = newData.map(
      (a) => a.adjusted_revpar_growth_rate
    );
    var sorted = adjusted_revpar_growth_rates.slice().sort(function (a, b) {
      return b - a;
    });
    var ranks = adjusted_revpar_growth_rates.map(function (v) {
      return sorted.indexOf(v) + 1;
    });
    var adjusted_revpar_growth_rates_5yr = newData.map(
      (a) => a.adjusted_revpar_growth_rate_5yr
    );
    var sorted_5yr = adjusted_revpar_growth_rates_5yr
      .slice()
      .sort(function (a, b) {
        return b - a;
      });
    var ranks_5yr = adjusted_revpar_growth_rates_5yr.map(function (v) {
      return sorted_5yr.indexOf(v) + 1;
    });

    for (var i = 0; i < newData.length; i++) {
      newData[i]['adjusted_rank'] = ranks[i];
      newData[i]['adjusted_rank_5yr'] = ranks_5yr[i];
    }

    // console.log(newData);

    setData(newData);
  };

  const onChangeDesc = (e) => {
    //  console.log(e.target.value);
    setDesc(e.target.value);
  };

  const onSubmitDesc = (e) => {
    e.preventDefault();
    const headers = { 'Access-Control-Allow-Origin': '*' };

    axios({
      method: 'POST',
      url: api_prefix_2 + '/data-table-description',
      headers: headers,
      data: { tooltip: desc },
    })
      .then((response) => {
        // console.log(desc);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };
  const uploadData = () => {
    var dataToUpload = data.slice();

    dataToUpload.sort((a, b) =>
      a.adjusted_rank > b.adjusted_rank
        ? 1
        : b.adjusted_rank > a.adjusted_rank
        ? -1
        : 0
    );
    // console.log(dataToUpload);
    const headers = { 'Access-Control-Allow-Origin': '*' };
    // axios
    //   .post(api_prefix_2 + '/data-table', dataToUpload, { headers })
    //   .then((response) => setValidation('Uploading is done.'))
    //   .catch((error) => {
    //     console.error('There was an error!', error);
    //   });
    axios({
      method: 'POST',
      url: api_prefix_2 + '/data-table',
      headers: headers,
      data: dataToUpload,
    })
      .then((response) => setValidation('Uploading is done.'))
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };

  return (
    <Styles>
      <h1>Scenario Analysis</h1>
      <Form onSubmit={onSubmitDesc}>
        <TextArea
          cols={50}
          id="desc"
          invalidText="Invalid error message."
          labelText="Please input the description of scenario analysis"
          placeholder="Placeholder text"
          onChange={onChangeDesc}
          rows={4}
        />
        <Button kind="primary" tabIndex={0} type="submit">
          Submit
        </Button>
      </Form>
      <div style={{ paddingTop: '40px', paddingBottom: '10px' }}>
        <Button
          style={{ marginRight: '10px' }}
          kind="tertiary"
          tabIndex={0}
          type="submit"
          onClick={resetData}
        >
          Reset Data
        </Button>
        <Button
          style={{ marginRight: '10px' }}
          kind="tertiary"
          tabIndex={1}
          type="submit"
          onClick={calculateData}
        >
          Calculate
        </Button>
        <Button
          style={{ marginRight: '10px' }}
          kind="tertiary"
          tabIndex={2}
          type="submit"
          onClick={uploadData}
        >
          Update
        </Button>
      </div>
      {/* <button onClick={resetData}>Reset Data</button>
      <button onClick={calculateData}>Calculate</button>
      <button onClick={uploadData}>Update</button> */}
      <p style={{ color: 'red' }}>{validation}</p>
      <Table
        columns={columns}
        data={data}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
      />
    </Styles>
  );
};
export default EditableTable;

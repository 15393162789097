import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts';
import { Content } from 'carbon-components-react/lib/components/UIShell';
import Footer from '../../components/Footer/Footer';
import AuthContext from '../../context/auth/authContext';
import ReactLoading from 'react-loading';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './_tabs.scss';
import { RadioButtonGroup, RadioButton } from 'carbon-components-react';
import axios from 'axios';
import TopArticles from './TopArticles';
import DashboardContext from '../../context/dashboard/dashboardContext';

const PostCovidNlpPage = () => {
  const authContext = useContext(AuthContext);
  const { loading} = authContext;
  const dashboardContext = useContext(DashboardContext);
  const { tableData, getFullDatatable } = dashboardContext;
  // const postcovidpageContext = useContext(PostcovidpageContext);
  // const { sentimentIndex, getSentimentIndex } = postcovidpageContext;
  const [sentimentIndex, setSentimentIndex] = useState(null);
  const api_prefix =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000/api/v5/sentiment'
      : '/api/sentiment';

  const api_prefix_postcovid =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000/api/v5/postcovidpage/sentiment-index'
      : '/api/postcovidpage/sentiment-index';

  useEffect(() => {
    window.scrollTo(0, 0);
    getFullDatatable();
    axios
      .get(api_prefix_postcovid)
      .then((res) => {
        setSentimentIndex(res.data);
      })
      .catch(function (error1) {
        console.log(error1);
      });

    axios
      .get(`${api_prefix}/positive?market=${selectedMarketId}`)
      .then((res) => {
        setArticles(res.data);
      })
      .catch(function (error1) {
        console.log(error1);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = [
    'Atlanta',
    'Austin',
    'Boston',
    'Charlotte',
    'Chicago',
    'Dallas',
    'DC Metro',
    'Denver',
    'Houston',
    'Indianapolis',
    'Los Angeles',
    'Louisville',
    'Memphis',
    'Miami',
    'Minneapolis',
    'Nashville',
    'New York',
    'Oakland',
    'Orange County',
    'Orlando',
    'Philadelphia',
    'Phoenix',
    'Portland',
    'Raleigh',
    'Salt Lake City',
    'San Antonio',
    'San Diego',
    'San Francisco',
    'San Jose',
    'Seattle',
    'Tampa',
  ];
  const [selectedMarketId, setSelectedMarketId] = useState('atlanta');
  const [selectedMarketName, setSelectedMarketName] = useState('Atlanta');
  const [selectedSentiment, setSelectedSentiment] = useState('positive');
  const [articles, setArticles] = useState(null);
  const DropdownTitle = () => {
    const onChange = (e) => {
      const marketId = tableData.find((m) => m.name === e.value).id;

      axios
        .get(`${api_prefix}/${selectedSentiment}?market=${marketId}`)
        .then((res) => {
          setArticles(res.data);
        })
        .catch(function (error1) {
          console.log(error1);
        });
      setSelectedMarketName(e.value);
      setSelectedMarketId(marketId);
    };
    const dropdown = (
      <div>
        <h2
          style={{
            paddingTop: '50px',
            paddingBottom: '20px',
          }}
        >
          Top Articles Based On Sentiment
        </h2>
        <section>
          <Dropdown
            style={{ width: '200px' }}
            placeholderClassName="postcovid-dropdown"
            options={options}
            onChange={onChange}
            value={selectedMarketName}
            placeholder="Select an option"
          />
        </section>{' '}
      </div>
    );
    return dropdown;
  };

  const IndexChart = () => {
    return (
      <div>
        <h1>Post-COVID NLP Insights</h1>
        <h2
          style={{
            paddingTop: '40px',
            paddingBottom: '20px',
          }}
        >
          City Sentiment Index
        </h2>
        <div style={{ backgroundColor: 'white', paddingLeft: '20px' }}>
          <p style={{ paddingTop: '20px', paddingLeft: '20px' }}>
            Net percent of positive articles related to a city
          </p>
          <ResponsiveContainer width="100%" height={350}>
            <ComposedChart
              data={sentimentIndex['data']}
              margin={{ top: 20, right: 80, bottom: 20, left: 20 }}
            >
              <XAxis
                dataKey="market"
                angle={-45}
                height={80}
                interval={0}
                textAnchor="end"
                // label={{ value: 'year', position: 'insideBottomRight', offset: -5 }}
              />
              <YAxis
                label={{
                  value: 'Sentiment Index',
                  angle: -90,
                  position: 'insideLeft',
                }}
              />
              <Tooltip />
              <Legend />
              <CartesianGrid stroke="#f5f5f5" />
              <Bar name="Market" dataKey="index" barSize={20} fill="#000066" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };

  const sentimentChange = (e) => {
    setSelectedSentiment(e);
    axios
      .get(`${api_prefix}/${e}?market=${selectedMarketId}`)
      .then((res) => {
        setArticles(res.data);
      })
      .catch(function (error1) {
        console.log(error1);
      });
  };

  const SentimentArticle = ({ article }) => {
    return (
      <div style={{ overflowY: 'auto', flex: 1, height: 400 }}>
        <h3>{article['title']}</h3>
        <p style={{ fontStyle: 'italic', paddingTop: '10px' }}>
          {article['source_name']}
        </p>
        <p
          className="publication-date"
          style={{
            paddingBottom: '10px',
          }}
        >
          {article['publication_date']}
        </p>

        {article['url'] != null && (
          <a
            el="noopener noreferrer"
            style={{
              paddingBottom: '10px',
            }}
            href={article['url']}
            target="_blank"
          >
            Link
          </a>
        )}

        <p style={{ paddingTop: '10px' }}>{article['snippet']}</p>
        <p style={{ paddingTop: '10px' }}>{article['body']}</p>
      </div>
    );
  };

  const SentimentArticles = () => {
    return (
      <div>
        <div
          style={{ display: 'flex', paddingTop: '10px', paddingBottom: '30px' }}
        >
          <div style={{ flex: '0 0 15%', paddingTop: '12px' }}>
            <p>Select sentiment of articles: </p>
          </div>
          <RadioButtonGroup
            style={{ flex: 1 }}
            name="radio-button-group"
            defaultSelected="positive"
            valueSelected={selectedSentiment}
            onChange={sentimentChange}
          >
            <RadioButton labelText="Positive" value="positive" id="positive" />
            <RadioButton labelText="Negative" value="negative" id="negative" />
          </RadioButtonGroup>
        </div>
        {articles.length > 0 ? (
          <Tabs>
            <TabList>
              {articles.slice(0, 5).map((item, i) => (
                <Tab>
                  <p>
                    {item.title.length > 100
                      ? item.title.substring(0, 100) + '...'
                      : item.title}
                  </p>
                </Tab>
              ))}
            </TabList>

            {articles.slice(0, 5).map((item, i) => (
              <TabPanel style={{ padding: '30px', paddingRight: '50px' }}>
                <div key={i} className="panel-content">
                  <SentimentArticle article={item} />
                </div>
              </TabPanel>
            ))}
          </Tabs>
        ) : (
          <p>No articles for the selected sentiment.</p>
        )}
      </div>
    );
  };

  const content = (
    <div className="bx--grid bx--grid--full-width landing-page">
      <div className="bx--row landing-page__banner landing-page__r1">
        <div className="bx--col-lg-16">
          <IndexChart />
          <TopArticles />
          <DropdownTitle />
          <SentimentArticles />
        </div>
      </div>
    </div>
  );
  return (
    <Content id="main-content">
      {!loading &&
      sentimentIndex !== null &&
      tableData !== null &&
      articles !== null ? (
        <>{content}</>
      ) : (
        <ReactLoading
          className="preloader"
          type={'spin'}
          color={'#0062ff'}
          height={60}
          width={60}
        />
      )}

      <Footer page="nlp" />
    </Content>
  );
};

export default PostCovidNlpPage;

import React from 'react';
import VariablepageContext from './variablepageContext';
import VariablepageReducer from './variablepageReducer';

const VariablepageState = props => {
  // const varStats = require('./../../contents/VariablePage/static/variables-MinMaxAvg.json');
  // const initialState = {
  //   varStats: varStats
  // };
  // const state = useReducer(VariablepageReducer, initialState)[0];

  return (
    <VariablepageContext.Provider
      value={{
        // varStats: state.varStats
      }}
    >
      {props.children}
    </VariablepageContext.Provider>
  );
};

export default VariablepageState;
